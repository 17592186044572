import { SchemaType } from '../apis/core/postPromotionReward';

export const PromotionRewardSecretKey = {
  Follow: '4MArfIGCuz7aCtjf',
  Comment: 'jb3NwR0vPZPG-4fg',
  Post: 'SE-HmJQJcmSB-n7l',
};

export const PromotionRewardSchemaType: Record<string, SchemaType> = {
  Follow: 'Application',
  Comment: 'PostComment',
  Post: 'Post',
};

export const PromotionRewardData = {
  Follow: {
    secretKey: PromotionRewardSecretKey.Follow,
    schemaType: PromotionRewardSchemaType.Follow,
  },
  Comment: {
    secretKey: PromotionRewardSecretKey.Comment,
    schemaType: PromotionRewardSchemaType.Comment,
  },
  Post: {
    secretKey: PromotionRewardSecretKey.Post,
    schemaType: PromotionRewardSchemaType.Post,
  },
};
