import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import ReactDOM from 'react-dom';

interface PropsType {
  closeModal: () => void;
  children: ReactNode;
}

const Modal = (props: PropsType) => {
  const closeModal = () => {
    props.closeModal();
  };

  return ReactDOM.createPortal(
    <div css={modal} onClick={closeModal}>
      <div css={modalBody} onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>,
    document.body
  );
};

const modal = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: rgba(54, 54, 54, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const modalBody = css`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding: 24px;
  width: 270px;

  background: #fff;
  backdrop-filter: blur(11px);
  border-radius: 14px;
`;

interface ModalPromotionProps {
  modalData: {
    isOpen: boolean;
    message: string;
  };
  closeModal: () => unknown;
}

export function ModalPromotion({ modalData, closeModal }: ModalPromotionProps) {
  return modalData.isOpen ? (
    <Modal closeModal={closeModal}>
      <ModalContainer>
        <Message>{modalData.message}</Message>
        <Button onClick={closeModal}>확인</Button>
      </ModalContainer>
    </Modal>
  ) : (
    <> </>
  );
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  text-align: center;
`;

const Message = styled.div`
  font-size: 14px;
  background-color: #fff;
`;

const Button = styled.button`
  display: inline-block;
  width: 100%;
  height: 30px;
  margin-top: 24px;
  background-color: #c869ff;
  color: #fff;
  border-radius: 15px;
  font-size: 14px;
`;
